import React from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'

const Join = () => {
    const form =React.useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_p7am7um', 'template_df51eqr', form.current, '9QpCfie00iYCAqYI2')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };


  return (
    <div className="Join" id ="join-us">
        <div className="left-j">
            <hr/>
            <div>
                <span className='stroke-text'>READY TO</span>
                <span>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <span className='stroke-text'>WITH US?</span>
            </div>
        </div>
        <div className="right-j">
            {/* <form ref={form} className="email-cointainer" onSubmit={sendEmail}>
                <input type="email" name="user_email" placeholder="Enter your Email address" />
                <button className=' btn-j'>Join Now</button>               
            </form> */}
          {/* <div><input type= "message " name='message'placeholder='Enter your message'/></div> */}
            <form ref={form} className="email-cointainer" onSubmit={sendEmail}>
               <input type="text" name="user_name" placeholder="Your Name" />
               <input type="email" name="user_email" placeholder="Your Email address" />
               <textarea name="user_message" placeholder="Your Message"></textarea>
               <button className='btn-j'>Send Message</button>
      </form>

        </div>

    </div>
  )
}

export default Join;