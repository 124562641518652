import React, { useState } from 'react';
import Home from '../../assets/home.jpg';
import { useNavigate } from 'react-router-dom';
import './Homepic.css';

const Homepic = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    // Toggle the state when the button is clicked
    setIsLoggedIn((prevIsLoggedIn) => !prevIsLoggedIn);
    navigate('/login');
  };

  return (
    <div className='homepi'>
      <img
        src={Home}
        alt=""
        style={{ width: '100%', maxWidth: '100%', height: '100%' }}
      />


        {/* Add the rest of your content here */}

        <div className='right-h'>
          <button className='btn' onClick={handleClick}>
            Login
          </button>
        </div>
      </div>
    
  );
};

export default Homepic;
